export const settingList = [
    'Abandoned Hospital',
    'Abandoned Mall',
    'Adult Daycare',
    'African Hair Braid Shop',
    'Airboat',
    'Airplane',
    'Airport',
    'Alternate Dimension',
    'Alternate Reality',
    'Ambulance',
    'Amusement Park',
    'Animal Shelter',
    'Another Planet',
    'Aquarium',
    'Art Studio',
    'Asteroid',
    'Auto Shop',
    'Bakery',
    'Bar',
    'Barber',
    'Barn',
    'Bathroom',
    'Battlefield',
    'Beach',
    'Beauty Supply',
    'Black Hole',
    'Blimp',
    'Blowout Bar',
    'Boat',
    'Brewery',
    'Buffet',
    'Bus',
    'Cafe',
    'Campsite',
    'Car',
    'Car Dealership',
    'Cat Cafe',
    'Catacombs',
    'Cave',
    'Celebration',
    'Ceramics Studio',
    'Class',
    'Clubhouse',
    'Church',
    'Cobblers',
    'College',
    'Community College',
    'Comet',
    'Concert',
    'Convent',
    'Convention',
    'Convenience Store',
    'Courtroom',
    'Daycare',
    'Den',
    'Dentist',
    'Desert',
    'Dirigible',
    'Distillery',
    'Docks',
    'Doctors Office',
    'Dragons Den',
    'Dungeon',
    "Enemy's Home",
    'Evil Lair',
    'Fairy Garden',
    'Farm',
    'Farmers Market',
    'Fast Food Restaurant',
    'Feast',
    'Field',
    'Film Set',
    'Firehouse',
    'Fire Truck',
    'Fishing Boat',
    'Foreign Country',
    'Forest',
    'Forge',
    'Foster Home',
    'Garage',
    'Garden',
    'Gas Station',
    'Graveyard',
    'Grocery Store',
    'Halfway House',
    'Hair Braid Shop',
    'Hairdresser',
    'Haunted House',
    'Hidden Oasis',
    'Home',
    'Home Office',
    'Hospital',
    'Hotel',
    'Hovel',
    'Hovercraft',
    'Ice Skating Rink',
    'Island',
    'Instrument Repair Shop',
    'Jail',
    'Job Site',
    'Kitchen',
    'Lake',
    'Library',
    'Mall',
    'Mansion',
    'Masseuse',
    'Meteorite',
    'Military Barracks',
    'Mill',
    'Mines',
    'Monastery',
    'Mosque',
    'Movie Theater',
    'Museum',
    'Nail Salon',
    'Natural Hair Salon',
    'Ocean',
    'Office',
    'Opera House',
    'Party',
    'Pet Store',
    'Playground',
    'Porch',
    'Port',
    'Prison',
    'Public Bathroom',
    'Puppet Show',
    'Rave',
    'Reality Show',
    'Refugee Camp',
    'Restaurant',
    'Rodeo',
    'Roller Rink',
    'Salon',
    'Sanctuary',
    'School',
    'Sea',
    'Secret Hideout',
    'Shack',
    'Shanty',
    'Shelter',
    'Ship',
    'Skate Park',
    'Slaughterhouse',
    'Space',
    'Space Junk',
    'Space Ship',
    'Sporting Event',
    'Star',
    'Steel Mill',
    'Store',
    'Strangers House',
    'Submarine',
    'Subway',
    'Swamp',
    'Taproom',
    'Tattoo Studio',
    'Temple',
    'Theatre',
    'Train',
    'Treehouse',
    'Tribal Land',
    'Tower',
    'Tundra',
    'Underwater',
    'Union Hall',
    'University',
    'Volcano',
    'Water Park',
    'Warehouse',
    'Woods',
    'Yacht',
    'Zoo'
];