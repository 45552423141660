export const charTraitList = [
    'Adventurous',
    'Affectionate',
    'Aggressive',
    'Ambitious',
    'Angry',
    'Anxious',
    'Apologetic',
    'Arrogant',
    'Articulate',
    'Attentive',
    'Bad-mannered',
    'Bold',
    'Bossy',
    'Brilliant',
    'Calm',
    'Careful',
    'Carefree',
    'Careless',
    'Caring',
    'Cautious',
    'Charming',
    'Cheerful',
    'Clumsy',
    'Coarse',
    'Compassionate',
    'Compliant',
    'Conceited',
    'Conscientious',
    'Confident',
    'Considerate',
    'Courageous',
    'Cowardly',
    'Critical',
    'Cruel',
    'Curious',
    'Dangerous',
    'Daring',
    'Decisive',
    'Demanding',
    'Demure',
    'Dependable',
    'Depressive',
    'Determined',
    'Devious',
    'Disagreeable',
    'Dictatorial',
    'Dishonest',
    'Disrespectful',
    'Domineering',
    'Dull',
    'Dutiful',
    'Easy-going',
    'Efficient',
    'Energetic',
    'Evil',
    'Faithful',
    'Fearless',
    'Fearful',
    'Foolish',
    'Friendly',
    'Funny',
    'Fussy',
    'Fun-loving',
    'Gentle',
    'Generous',
    'Graceful',
    'Greedy',
    'Hardworking',
    'Happy',
    'Harsh',
    'Hateful',
    'Helpful',
    'Honest',
    'Humanitarian',
    'Humorous',
    'Humble',
    'Idealistic',
    'Imaginative',
    'Impatient',
    'Impolite',
    'Inconsiderate',
    'Independent',
    'Industrious',
    'Innocent',
    'Interfering',
    'Intelligent',
    'Jealous',
    'Judgemental',
    'Kindly',
    'Lazy',
    'Leader',
    'Likeable',
    'Lively',
    'Loving',
    'Lovable',
    'Loyal',
    'Manipulative',
    'Materialistic',
    'Mature',
    'Melancholic',
    'Miserable',
    'Mysterious',
    'Naive',
    'Nervous',
    'Opinionated',
    'Optimistic',
    'Overbearing',
    'Passive',
    'Patient',
    'Personable',
    'Petulant',
    'Polite',
    'Proud',
    'Prying',
    'Rational',
    'Realistic',
    'Rebellious',
    'Reckless',
    'Reflective',
    'Reliable',
    'Reserved',
    'Responsible',
    'Restless',
    'Rigid',
    'Sarcastic',
    'Secretive',
    'Selfish',
    'Sensible',
    'Sensitive',
    'Sensuous',
    'Serious',
    'Shy',
    'Sly',
    'Solitary',
    'Stable',
    'Stingy',
    'Strict',
    'Stubborn',
    'Submissive',
    'Thoughtful',
    'Thoughtless',
    'Timid',
    'Tolerant',
    'Trailblazing',
    'Trusting',
    'Trustworthy',
    'Unfriendly',
    'Unkind',
    'Unstable',
    'Untidy',
    'Wary',
    'Wicked',
    'Wise',
    'Witty'
];