<template>
  <div id="#app">
    <app-header></app-header>
    <app-generate></app-generate>
    <app-footer></app-footer>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style>
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #a29dfc;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
