export const genderList = [
    'Agender',
    'Androgyne',
    'Bigender',
    'Butch',
    'Cis-Man',
    'Cis-Woman',
    'Demiboy',
    'Demigirl',
    'Femme',
    'Gender fluid',
    'Gender neutral',
    'Genderqueer',
    'Man',
    'Nonbinary',
    'Neutrois',
    'Pangender',
    'Transfeminine',
    'Transmasculine',
    'Trans-Man',
    'Trans-Woman',
    'Two-Spirit',
    'Woman',
];