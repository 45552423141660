export const genreList = [
    'Action/Adventure',
    'Comedy',
    'Crime Caper',
    'Drama',
    'Fairy Tale',
    'Fantasy',
    'Ghost Story',
    'Historical Fiction',
    'Horror',
    'Mystery',
    'Political Satire',
    'Romance',
    'Romantic Comedy',
    'Sci-Fi',
    'Spy',
    'Suspense',
    'Thriller'
];